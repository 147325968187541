.show-credential-container {
    position:fixed;
    top: 50%;
    left: 50%;
    width:50em;
    height:42em;
    margin-top: -21em; /*set to a negative number 1/2 of your height*/
    margin-left: -25em; /*set to a negative number 1/2 of your width*/
    border: 5px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3rem;
    gap: 1rem;
}

.copy-icon {
    color: white;
    margin-left: 15px;
    font-size: 35px !important;
}

.show-credential-input-field-container {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-credential-input-field {
    border-radius: 0.5rem;
    width: 80%;
    pointer-events: none;
}

.animate__animated.animate__rubberBand {
    --animate-duration: 1s;
}

.delete-credential-icon {
    color: red;
    font-size: 3rem !important;
}

.delete-button-yes {
    font-size: 3rem !important;
    float: left;
    color: green;
}

.delete-button-no {
    font-size: 3rem !important;
    float: right;
    color: red;
}

@media (max-width: 1025px) and (orientation: portrait)
{
    .show-credential-input-field-container {
        width: 80%;
    }
}

@media (max-width: 769px) and (orientation: portrait)
{
    .show-credential-container {
        width:36em;
        height:36em;
        margin-top: -18em; /* set to a negative number 1/2 of your height */
        margin-left: -18em; /* set to a negative number 1/2 of your width */
    }
    .copy-icon {
        font-size: 30px !important;
    }
}

@media (max-width: 610px) and (orientation: portrait)
{
    .show-credential-container {
        width:30em;
        height:36em;
        margin-top: -18em; /* set to a negative number 1/2 of your height */
        margin-left: -15em; /* set to a negative number 1/2 of your width */
    }
}

@media (max-width: 481px) and (orientation: portrait)
{
    .show-credential-container {
        width:20em;
        height:30em;
        margin-top: -15em; /* set to a negative number 1/2 of your height */
        margin-left: -10em; /* set to a negative number 1/2 of your width */
        gap: 0.1rem;
    }
    .show-credential-input-field-container {
        width: 85%;
        margin: 0px auto !important;
    }
    .MuiOutlinedInput-root {
        height: 75% !important;
    }
    .copy-icon {
        font-size: 28px !important;
    }
}

@media (max-width: 1025px) and (orientation: landscape)
{
    .show-credential-container {
        width:40em;
        height:36em;
        margin-top: -18em; /* set to a negative number 1/2 of your height */
        margin-left: -20em; /* set to a negative number 1/2 of your width */
    }
    .show-credential-input-field-container {
        width: 70%;
    }
}

@media (max-width: 900px) and (orientation: landscape)
{
    .show-credential-container {
        position: absolute;
        top: 25rem;
    }
    .show-credential-input-field-container {
        width: 80%;
    }
    .spacer {
        height: 13rem;
    }
}