.add-credential-container {
    position: fixed;
    top: 50%;
    left: 50%;
    width:50em;
    height:42em;
    margin-top: -21em; /* set to a negative number 1/2 of your height */
    margin-left: -25em; /* set to a negative number 1/2 of your width */
    border: 5px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3rem;
    gap: 1rem;
}

.create-credential-input-field {
    border-radius: 0.5rem;
    width: 60%;
}

.add-credential-button {
    width: 40%;
    height: 8%;
    border-radius: 30px !important;
    font-family: 'Quicksand', sans-serif !important;
    font-size: 1rem !important;
    padding: 0px !important;
}

@media (max-width: 769px) and (orientation: portrait)
{
    .add-credential-container {
        width:36em;
        height:36em;
        margin-top: -18em; /* set to a negative number 1/2 of your height */
        margin-left: -18em; /* set to a negative number 1/2 of your width */
    }
    .create-credential-input-field {
        width: 75%;
    }
    .add-credential-button {
        width: 45%;
    }
}

@media (max-width: 610px) and (orientation: portrait)
{
    .add-credential-container {
        width:30em;
        height:36em;
        margin-top: -18em; /* set to a negative number 1/2 of your height */
        margin-left: -15em; /* set to a negative number 1/2 of your width */
    }
    .create-credential-input-field {
        width: 75%;
    }
    .add-credential-button {
        width: 45%;
    }
}

@media (max-width: 481px) and (orientation: portrait)
{
    .add-credential-container {
        width:20em;
        height:30em;
        margin-top: -15em; /* set to a negative number 1/2 of your height */
        margin-left: -10em; /* set to a negative number 1/2 of your width */
        gap: 0.7rem;
    }
    .create-credential-input-field {
        width: 80%;
        margin: 0px auto !important;
    }
    .MuiOutlinedInput-root {
        height: 75% !important;
    }
    .add-credential-button {
        width: 45%;
        font-size: 13px !important;
    }
}

@media (max-width: 1025px) and (orientation: landscape)
{
    .add-credential-container {
        width:40em;
        height:36em;
        margin-top: -18em; /* set to a negative number 1/2 of your height */
        margin-left: -20em; /* set to a negative number 1/2 of your width */
    }
    .create-credential-input-field {
        width: 70%;
    }
    .add-credential-button {
        width: 45%;
    }
}

@media (max-width: 900px) and (orientation: landscape)
{
    .add-credential-container {
        position: absolute;
        top: 25rem;
    }
    .create-credential-input-field {
        width: 75%;
    }
    .spacer {
        height: 13rem;
    }
}