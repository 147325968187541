.credential-background-image {
    background-image: url("../../images/credential-page.jpg");
    height: 100vh;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.credential-container {
    margin: auto;
    padding-top: calc(110px + (142 - 110) * ((100vw - 300px) / (1600 - 300)));
}

.each-credential-container {
    margin: auto;
    display: flex;
    background-color: #1E2029;
    align-items: center;
    justify-content: center;
    width: calc(120px + (225 - 120) * ((100vw - 300px) / (1600 - 300)));
    height: calc(120px + (225 - 120) * ((100vw - 300px) / (1600 - 300)));
    border-radius: 15%;
    flex-direction: column;
    border: 5px solid white;
}

.each-credential-container:hover {
    cursor: pointer;
}

.credential-grid {
    margin-bottom: 40px !important;
}

.add-new-credential {
    border: 5px dashed white;
}

.each-credential-platfrom {
    margin-bottom: 0;
    color: #ffffff;
    font-size: calc(15px + (22 - 15) * ((100vw - 300px) / (1600 - 300))); /* 1.5rem */
    font-family: 'Source Sans Pro', sans-serif;
}